import React from 'react'
import styles from './PartnerList.module.scss'

const logoContext = require.context('./img/anthem/logos', true, /\.webp$/)

const links = {
	// foundational
	adcouncil: 'https://www.adcouncil.org/',
	bornthisway: 'https://bornthisway.foundation/',
	feedingamerica: 'https://www.feedingamerica.org/',
	glaad: 'https://www.glaad.org/',
	mozilla: 'https://foundation.mozilla.org/en/',
	naacp: 'https://naacp.org/',
	nrdc: 'https://www.nrdc.org/',
	wwf: 'https://www.worldwildlife.org/',
	xq: 'https://xqsuperschool.org/',

	// media

	blavity: 'https://blavity.com/',
	fc: 'https://www.fastcompany.com/',
	goodup: 'https://www.upworthy.com/',
	mtv: 'https://www.mtv.com/',
	thedots: 'https://the-dots.com/',
	wsj: 'https://www.wsj.com/',

	// event
	hopin: 'https://hopin.com/',
	story_lab: 'https://www.brooklynstorylab.net/'
}

const Logos = ({ logos }) => {
	return <div>
		{logos.map(logo =>
			<a
				key={logo}
				href={links[logo]}
				target="_blank"
				rel="noreferrer"
			>
				<img
					key={logo}
					className={styles.logo}
					src={logoContext(`./anthem-website-logos_${logo}.webp`).default}
				/>
			</a>
		)}
	</div>
}

const PartnerList = () => {
	return <div className={styles.root}>
		<a rel="noreferrer" target="_blank" href="https://www.anthemawards.com/partners/" className={styles.heading}>Partners & Sponsors</a>
		<Logos logos={[
			'adcouncil',
			'bornthisway',
			'fc',
			'glaad',
			'naacp',
			'nrdc-e1632433716712',
			'pledge1',
			'toms',
			'story_lab'
		]} />
	</div>
}

export default PartnerList
