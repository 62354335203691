import React, { useMemo, useRef, useState } from 'react'
import Identify from '../utils/Identify'
import Help from './Help'
import Loader from './Loader'
import axios from 'axios'
import Config from '../Config'
import crop from '../img/anthem/aa-camera.svg'
import Button from './Button'

export default function VideoRecorderIOS ({ piece = null, finalize = null, official = false }) {
	const file = useRef()
	const [uploading, setUploading] = useState(false)
	const [ctaText, setCtaText] = useState('')
	const identifyInstance = new Identify()
	const isMobile = identifyInstance.mobileOS() === 'iOS' || identifyInstance.mobileOS() === 'Android'

	const wordCount = useMemo(() => {
		if (!ctaText.trim()) {
			return 0
		}
		return ctaText.trim().split(/\s+/).length
	}, [ctaText])

	const tooManyWords = useMemo(() => {
		return wordCount > 20
	}, [wordCount])

	const fieldIsDisabled = tooManyWords || !ctaText.trim()

	const loadFile = async (e) => {
		const f = e.target.files[0]
		const fileName = e.target.files[0].name
		const fileType = e.target.files[0].type
		const fileSize = e.target.files[0].size

		if (fileType === 'video/webm' || fileType === 'video/mov' || fileType === 'video/avi' || fileType === 'video/mp4' || fileType === 'video/quicktime' || fileType === 'video/mpeg') {
			if (fileSize >= 30 * 1024 * 1024) {
				// eslint-disable-next-line no-undef
				alert('Video files must be below 30 MB')
				return
			}

			const extension = fileName.split('.')
			const ext = extension[extension.length - 1]

			setUploading(true)
			const signedURL = await axios.get(`${Config.endpoint}/signedURL?ext=${ext}&type=${encodeURIComponent(fileType)}`)

			let error = false
			try {
				await axios.put(signedURL.data.url, f, {
					headers: {
						'Content-Type': fileType
					}
				})
			} catch (err) {
				error = true
				// eslint-disable-next-line no-undef
				alert('Could not upload video, try again.')
				return
			}

			if (!error) {
				// save to db
				console.log('OFFICIAL:', official)
				let videoID = null
				try {
					videoID = await axios.post(`${Config.endpoint}/anthem_save2024`, {
						hash: signedURL.data.id,
						official: official,
						pieceID: piece.PieceID,
						ctaText,
						ext: ext
					})
				} catch (err) {
					// eslint-disable-next-line no-undef
					alert('Could not save video to server')
					return
				}

				setUploading(false)
				finalize(videoID.data.videoID)
			}
		} else {
			// eslint-disable-next-line no-undef
			alert('You must choose a mp4, mov, webm, or avi video file.')
		}
	}

	return (
		<div className="flex">
			<div>
				<img src={crop} alt="How to crop" className="crop-gif" />
				<h1>Before You Upload, remember:</h1>
				<ol style={{ maxWidth: 500, margin: '0 auto 40px auto', textAlign: 'left' }}>
					<li>Keep file to no more than 30MBs.</li>
					<li>Light your face from the front.</li>
					<li>Limit your background noise so we can hear every word.</li>
					<li>Center yourself in the frame.</li>
					<li>Consider your background and use a clean and appropriate space.</li>
					<li>Please leave a few seconds before and after you speak.</li>
					<li>Remember to be yourself!</li>
				</ol>
				<div><strong>Submit the text of your CTA here</strong></div>
				<textarea
					style={{
						display: 'block',
						margin: '20px auto 10px auto',
						width: 600
					}}
					value={ctaText}
					onChange={event => {
						setCtaText(event.target.value)
					}}
				/>

				<div>{tooManyWords
					? <span style={{ color: '#f79b77' }}>Word limit exceeded</span>
					: <span>{20 - wordCount} {wordCount === 19 ? 'word' : 'words'} remaining</span>
				}</div>
				<br/>
				<Button nopad>
					<label
						style={{
							padding: '20px 40px',
							cursor: tooManyWords ? 'not-allowed' : 'pointer',
							...({}),
						}}
						htmlFor="file" className="inputlabel">Upload video</label>
				</Button>
				<br />
				<br />
				<input
					disabled={tooManyWords}
					id="file"
					className="inputfile"
					ref={file}
					type="file"
					accept="video/*;capture=camcorder"
					onChange={loadFile}
					onClick={(e) => {
						if (!ctaText.trim()) {
							e.preventDefault()
							e.stopPropagation()
							// eslint-disable-next-line no-undef
							alert('You must enter CTA text before proceeding.')
						}
					}}
				/>
				{uploading ? <Loader /> : null }

				<Help />
			</div>
		</div>
	)
}
