import React from 'react'

export default function Help () {
	return <>
		<div>
			Kara Cleary from the Anthem Customer Service Team is available to answer any questions you may have.<br />
			Email: <a
				style={{ color: '#F79B77' }}
				href="mailto:kara@anthemawards.com">kara@anthemawards.com</a><br />
			Call: +1 212 675 3555<br />
		</div>

		<br />
	</>
}
