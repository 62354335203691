import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import styles from './HomePlaceholder.module.css'

export default function HomePlaceholder () {
	return (
		<div className={styles.root}>
			<Header />

			<div className={styles.content}>
				<div className={styles.topText}>The 3rd Annual</div>

				<div className={styles.text} />

				{/* <div className={styles.bottomText}> */}
				{/*	Coming Soon */}
				{/* </div> */}

				<div className={styles.moreText}>
					<p>
            Celebrating Purpose &amp; Mission-Driven Work of People, Companies
            and Organizations Worldwide.
					</p>

					<p>
					Winners will be publicly announced on Tuesday, January, 30th!
					The announcement will showcase the most impactful work of the year
					along with Call to Action Speeches from all of our Honorees and Winners!
					</p>

					{/* <p style={{ textTransform: 'uppercase', fontSize: 26 }}> */}
					{/*	Monday, February 28, 5pm ET */}
					{/* </p> */}
				</div>
			</div>

			<Footer />
		</div>
	)
}
